import { Component } from "react";
import styled from "styled-components";
import GameOver from "./GameOver";
import GameCompleted from "./GameCompleted";
import Question from "./Question";
//const { translate } = require("../translate");

const QuizWindow = styled.div`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  margin-top: 10vh;
`;

class Guessthelanguage extends Component {
  getOptions(languages, answer) {
    let options = [];
    if (localStorage.getItem('options') !== null && answer === localStorage.getItem('currentLanguage')) {
      options = JSON.parse((localStorage.getItem('options')))
      // localStorage.setItem('options', JSON.stringify(''))
    }
    else {
      let randm = 0;
      languages.splice(languages.indexOf(answer), 1);
      for (var i = 0; i < 3; i++) {
        randm = Math.floor(Math.random() * languages.length);
        options.push(languages[randm]);
        languages.splice(randm, 1);
      }
      options.push(answer);

      const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);
      options = shuffle(options)
      localStorage.setItem('options', JSON.stringify(options))
    }
    return (options);
  }

  setStateToLocalStorage(){
    localStorage.setItem('lives', JSON.stringify(3))
    localStorage.setItem('pts', JSON.stringify(0))
  }

  resetOptionsColor() {
    var buttons = document.getElementsByTagName("button");
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].style.backgroundColor = "#161a31";
    }
  }

  getRandomLanguage = (data) => {
    let languages = data.map((element) => {
      return element.Language;
    });

    return languages[Math.floor(Math.random() * languages.length)]
  }

  componentDidMount() {
    //this.setStateToLocalStorage()
  //   const { data, setQuiz, lives } = this.props;
  //   const languages = data.map((element) => {
  //     return element.Language;
  //   });

  //   const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);
  //   let quiz = [];
  //   if (localStorage.getItem('quiz') !== '') {
  //     quiz = JSON.parse(localStorage.getItem('quiz'))
  //   }
  //   else {
  //     quiz = data.map((item) => ({
  //       text: item.text,
  //       options: this.getOptions([...languages], item.Language),
  //       answer: item.Language,
  //       }
  //     ))
  //   }
  //   localStorage.setItem('quiz', JSON.stringify(shuffle(quiz)))
    
  //   setQuiz(shuffle(quiz));
  // }

  // componentWillUnmount(){
  //   localStorage.setItem('options', JSON.stringify(''))
        //localStorage.setItem('options', JSON.stringify([]))
   }

  render() {
    let { data, quiz, setCurrentLanguage, setQuiz, lives, setLives, pts, setPoints, guessedCorrectly, record } = this.props;
    
    if (localStorage.getItem('data') !== null)
      data = JSON.parse(localStorage.getItem('data'))

    const languages = data.map((element) => {
      return element.Language;
    });
    const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);
    quiz = data.map((item) => ({
      text: item.text,
      options: JSON.parse(localStorage.getItem('lives')) !== 0 && JSON.parse(localStorage.getItem('start')) === true ?
      this.getOptions([...languages], item.Language) : [],
      answer: item.Language,
    }
    ))
    // while (quiz.length > 0)
    //   quiz = [... this.state.quiz]
    if (JSON.parse(localStorage.getItem('quiz')) !== null  && JSON.parse(localStorage.getItem('start')) === true)
      // localStorage.setItem('quiz', JSON.stringify(shuffle(quiz)))
      quiz = JSON.parse(localStorage.getItem('quiz'))
      // console.log(quiz)

    let indx = Math.floor(Math.random() * quiz.length);

    if (localStorage.getItem('currentLanguage') !== null) {
      const currentLanguage = JSON.parse(localStorage.getItem('currentLanguage'))
      // let isCurrentLanguage = (e) => e.answer === currentLanguage ---- GIVES ERROR (NOT POSSIBLE)
      // indx = quiz.findIndex(isCurrentLanguage(currentLanguage))

      // To find the index of an object in an array, by a specific property:
      // Use the map() method to iterate over the array, returning only the value of the relevant property.
      // Call the indexOf() method on the returned from map array. The indexOf method returns the index of the first occurrence of a value in an array.
      let isCurrentLanguage = (l) => l === currentLanguage
      indx = quiz.map(e => e.answer).findIndex((isCurrentLanguage))
    }
    else {
      localStorage.setItem('currentLanguage', JSON.stringify(quiz[indx].answer))
    }
    //setCurrentLanguage(quiz[indx])
  
    if (localStorage.getItem('lives') !== null) {
      lives = JSON.parse(localStorage.getItem('lives'))
    }
    if (localStorage.getItem('pts') !== null) {
      pts = JSON.parse(localStorage.getItem('pts'))
    }
    if (localStorage.getItem('guessedCorrectly') !== null) {
      guessedCorrectly = JSON.parse(localStorage.getItem('guessedCorrectly'))
    }
    if (localStorage.getItem('record') !== null) {
      record = JSON.parse(localStorage.getItem('record'))
    }
    // if(lives === 0){
    //   localStorage.setItem('currentLanguage', JSON.stringify(this.getRandomLanguage(JSON.parse(localStorage.getItem('data')))))
    //   // localStorage.setItem('quiz')
    // }

  //  if (lives === 0) {
  //    localStorage.setItem('currentLanguage', JSON.stringify(''))
  //    localStorage.setItem('options', JSON.stringify(''))
  //    localStorage.setItem('quiz', JSON.stringify(''))
  // }

    if (lives > 0) {
      setTimeout(() => {
        // quiz.splice(indx, 1);
        // setQuiz(quiz);
        this.resetOptionsColor();
      }, 1000);
    }


    return (
      <>
        {lives > 0 && (
          <>
            <div>
              <h2>
                Lives: {lives}; Points: {pts}
              </h2>
            </div>

            <QuizWindow>
              {quiz[indx] && (
                <Question
                  //showText={this.props.showText}
                  question={quiz[indx].text}
                  answer={quiz[indx].answer}
                  options={quiz[indx].options}
                  showText={this.props.showText}
                  pts={pts}
                  setPoints={setPoints}
                  lives={lives}
                  setLives={setLives}
                  guessedCorrectly={guessedCorrectly}
                  quiz={quiz}
                  setQuiz={setQuiz}
                  indx={indx}
                />
              )}
            </QuizWindow>
          </>
        )}
        {lives === 0 && (
          <>
            <QuizWindow>
              <GameOver guessedCorrectly={guessedCorrectly} pts={pts} reStartGame={this.props.reStartGame} startGame={this.props.startGame} />
            </QuizWindow>
          </>
        )}
        {quiz.length === 0 && (
          <>
            <QuizWindow>
              <GameCompleted pts={pts} />
            </QuizWindow>
          </>
        )}
      </>
    )
  }
}
export default Guessthelanguage;
