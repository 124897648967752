import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import Countdown from "./Countdown";
import Popup from "./Popup"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

let Title = styled.h1`
margin-top: 2vh;
font-size: 48px;
margin-right: 24vw;
`;

let Points = styled.p`
  font-size: 24px;
  margin-right: 15vw;
`;

let TryAgain = styled.div`
position: relative;
float:left;
top: 10vw;
left:10vw
`;

const About = styled.div`
position: fixed;
right: 0px; bottom: 0px;
  margin-bottom: 20px;
  margin-right: 10px;
  text-align: right;
`;

let SocialSection = styled.div`
position: relative;
right:7vw
`;

const isMobile = (window.screen.width < 600);

class GameOver extends React.Component {
  // componentDidMount(){
  //   if (localStorage.getItem('last_played') === null) {
  //     localStorage.setItem('last_played', JSON.stringify(new Date()))
  //   }
  // }

    render() {
    const { pts } = this.props;
    let shareTitle = `I guessed ${pts} languages on languiz.com!`;

    const GlobalStyle = createGlobalStyle`
    html {
      scroll-behavior: smooth;
    }
    body {
      min-height: 100%;
      z-index: 0;
      overflow: auto;
    }
    :root {
      overflow-x: hidden;
      height: 100%;
    }`

    if (isMobile)
      TryAgain = styled.div`
      position: relative;
      float:left;
      top: 3vw;
      left:25vw;
 `;

 if (localStorage.getItem('last_played') === null) {
  localStorage.setItem('last_played', JSON.stringify(new Date()))
}

    const currentDate = new Date();
    let date = currentDate.getDate() + 1
    let month = currentDate.getMonth() + 1
    
    let shorterMonths = [2, 4, 6, 9, 11]
    let longerMonths = [1, 3, 5, 7, 8, 10, 12]
    if (shorterMonths.indexOf(month) > -1){
      if (date === 31)
        month += 1
      date = Number(currentDate.getDate() + 1) % 30
    }
    else
    if (longerMonths.indexOf(month) > -1){
      if (date === 32)
        month += 1
      date = Number(currentDate.getDate() + 1) % 31
    }
    let dateString =  (date / 10 >= 1) ? String(date) : `0${date}`
    let monthString =  (month / 10 >= 1) ? String(date) : `0${month}`
    
    const year =
      currentDate.getMonth()() + 1 === 12 && currentDate.getDate() === 31
        ? currentDate.getFullYear() + 1
        : currentDate.getFullYear();
    return (
      <><GlobalStyle></GlobalStyle>
      {!isMobile && 
        (<TryAgain>
        <h3>Play again in:</h3>
        <Countdown date={`${year}-${monthString}-${dateString}T00:00:00`} />
        </TryAgain>)}
        <Title>Game Completed!</Title>
        {
          <>
            <Points>
              Well done language master, you made it to the end! <br /> You guessed {pts} languages
            </Points>
          </>
        }
        <br />
        <SocialSection>
          <EmailShareButton
            subject={shareTitle}
            body={"Test your skills at www.languiz.com"}
          >
            <EmailIcon size={48} round={true} />
          </EmailShareButton>

          <FacebookShareButton
            url={"www.languiz.com"}
            quote='share your score!'
            hashtag={'#languiz'}
          >
            <FacebookIcon size={48} round={true} />
          </FacebookShareButton>

          <LinkedinShareButton 
            url={"www.languiz.com"}
            title={"The Language Quiz"}
            summary={`${shareTitle}\nTest your skills at languiz.com`}
            description={shareTitle}>
            <LinkedinIcon size={48} round={true} />
          </LinkedinShareButton>

          <TwitterShareButton 
            url={shareTitle}
            hashtags={['languiz']}>
            <TwitterIcon size={48} round={true} />
          </TwitterShareButton>

          <WhatsappShareButton  
            url={"www.languiz.com"}
            title={shareTitle}
            separator={"\nTest your skills at "}>
            <WhatsappIcon size={48} round={true} />
          </WhatsappShareButton>
        </SocialSection>
        {isMobile && 
        (<TryAgain>
        <h3>Play again in:</h3>
        <Countdown date={`${year}-${monthString}-${dateString}T00:00:00`} />
        </TryAgain>
        )}
        {isMobile && <br/>}
        <About>
          <Popup></Popup>
        </About>
      </>
    );
  }
}

export default GameOver;
