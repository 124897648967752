import React from "react";
import styled, { css } from "styled-components/macro";
import "./Popup.css"



class Start extends React.Component {
    componentDidMount(){
        const openModalButtons = document.querySelectorAll('[data-modal-target]')
        const closeModalButtons = document.querySelectorAll('[data-close-button]')
        const overlay = document.getElementById('overlay')

        openModalButtons.forEach(button => {
        button.addEventListener('click', () => {
            const modal = document.querySelector(button.dataset.modalTarget)
            openModal(modal)
        })
        })

        overlay.addEventListener('click', () => {
        const modals = document.querySelectorAll('.modal.active')
        modals.forEach(modal => {
            closeModal(modal)
        })
        })

        closeModalButtons.forEach(button => {
        button.addEventListener('click', () => {
            const modal = button.closest('.modal')
            closeModal(modal)
        })
        })

        function openModal(modal) {
        if (modal == null) return
        modal.classList.add('active')
        overlay.classList.add('active')
        }

        function closeModal(modal) {
        if (modal == null) return
        modal.classList.remove('active')
        overlay.classList.remove('active')
        }
            }

    render() {
    return (
      <>
        <a data-modal-target="#modal" href="#"> About me </a>
        <div className="modal" id="modal">
            <div className="modal-header">
                <div className="title">Hi, I'm Dori</div>
                <button data-close-button className="close-button">&times;</button>
            </div>
            <div className="modal-body">
                {/* I'm 27 years old, love learning different languages,<br />
                and also, I have a BA in Linguistics.<br />
                During the last year, I had been working on a project that combines this area with my passion towards computers.<br />
                I decided to jump on the mission to master Javascript, in order to build Languiz, an interactive quiz in which you need to guess the languages by the text presented.<br />
                Good luck, Hope you will enjoy,<br />
                and don't forget to share your scores &#58;&#41; */}
                <ul style={{paddingLeft: '0.75vw', listStyleType: 'none'}}>
                    <li>I'm 27 years old, and love learning different languages.<br />
                    <text style={{textTransform:'capitalize'}}>Also, </text>I have a BA in Linguistics.</li>
                    <li>I always wanted to create a project that combines this area with my passion to computers and programming.</li>
                    <li>I built Languiz, an interactive quiz in which you guess the languages by the text presented.</li>
                    <li>Good luck, enjoy, and don't forget to share your scores &#58;&#41;</li>
                </ul>
            </div>
        </div>
        <div id="overlay"></div>
      </>
    );
  }
}
export default Start;
