import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Button } from "./Button";
import Countdown from "./Countdown";
import Popup from "./Popup"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

let Title = styled.h1`
  margin-top: 2vh;
  font-size: 48px;
  margin-right: 15vw;
`;

let Result = styled.p`
  font-size: 24px;
  margin-top: 0vw;
  margin-right: 2vw;
`;

let CorrectList = styled.div`
  margin-top: 2vw;
  margin-right: -1vw;
`;

let CorrectLanguage = styled.li`
  &:before {
    content: "✓";
  }
`;

let TryAgain = styled.div`
     position: relative;
     float:left;
     top: 10vw;
     left:10vw
`;

const About = styled.div`
position: fixed;
right: 0px; bottom: 0px;
  margin-bottom: 20px;
  margin-right: 10px;
  text-align: right;
`;

let SocialSection = styled.div`
position: relative;
right:0vw
`;

class GameOver extends React.Component {
  componentDidMount(){
    if (localStorage.getItem('last_played') === null) {
      localStorage.setItem('last_played', JSON.stringify(new Date().toLocaleDateString()))
    }

    // if (localStorage.getItem('start') !== null && localStorage.getItem('lives') !== null)
    //     if (localStorage.getItem('start') === 'false' && localStorage.getItem('lives') === '0')
    //       this.props.startGame()
  }

  render() {
    //localStorage.setItem('currentLanguage', JSON.stringify(''))

    const GlobalStyle = createGlobalStyle`
    html {
      scroll-behavior: smooth;
    }
    body {
      min-height: 100%;
      z-index: 0;
      overflow: scroll;
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch"
    }
    :root {
      overflow-x: hidden;
      height: 100%;
    }`

    const isMobile = (window.screen.width < 600);
    if (isMobile){
      TryAgain = styled.div`
      position: relative;
      float:left;
      top: 3vw;
      left:25vw;
 `;
      CorrectList = styled.div`
      margin-top: 20vw;
      margin-right: -1vw;
    `;
    }

    // if (localStorage.getItem('last_played') === null) {
    //   localStorage.setItem('last_played', JSON.stringify(new Date()))
    // }

    const currentDate = new Date();
    let date = currentDate.getDate() + 1
    let month = currentDate.getMonth() + 1
    
    let shorterMonths = [2, 4, 6, 9, 11]
    let longerMonths = [1, 3, 5, 7, 8, 10, 12]
    if (shorterMonths.indexOf(month) > -1){
      if (date === 31)
        month += 1
      date = Number(currentDate.getDate() + 1) % 30
    }
    else
    if (longerMonths.indexOf(month) > -1){
      if (date === 32)
        month += 1
      date = Number(currentDate.getDate() + 1) % 31
    }
    const dateString =  (date / 10 >= 1) ? String(date) : `0${date}`
    const monthString =  (month / 10 >= 1) ? String(month) : `0${month}`
    
    const year =
      currentDate.getMonth() + 1 === 12 && currentDate.getDate() === 31
        ? currentDate.getFullYear() + 1
        : currentDate.getFullYear();
        
    const { guessedCorrectly, pts } = this.props;
    const listItems = guessedCorrectly.map((l) => (
      <CorrectLanguage key={l}>{l}</CorrectLanguage>
    ));
    let shareTitle_suffix = '';
    pts <= 1 ? shareTitle_suffix='' : shareTitle_suffix='s';
    let shareTitle = `I guessed ${pts} language${shareTitle_suffix} on the language quiz`;
    pts === 0 ? (shareTitle += `, that's a tough one!`) : (shareTitle += "!");
    return (
      <> <GlobalStyle></GlobalStyle>
        
        {!isMobile && 
        (<TryAgain>
        <h3>Try again in:</h3>
        <Countdown date={`${year}-${monthString}-${dateString}T00:00:00`} />
        </TryAgain>)}
        {/* {console.log(`${year}-${monthString}-${dateString}T00:00:00`)} */}
        <Title>Game Over</Title>
        {pts > 1 && (
            <Result>You guessed {pts} languages! Well done</Result>
        )}
        {pts === 1 && (
            <Result>You guessed {pts} language! Well done</Result>
        )}
        {pts === 0 && <Result>You didn't guess any languages today,<>{window.screen.width <= 415 ? " " : <br />}</>better luck next time!</Result>}
        {/* {pts > 0 && <br />} */}
        {<br />}
        <SocialSection >
          <EmailShareButton
            subject={shareTitle}
            body={"Test your skills at languiz.com"}
          >
            <EmailIcon size={48} round={true} />
          </EmailShareButton>

          <FacebookShareButton
            url={"https://www.languiz.com"}
            quote='share your score!'
            hashtag={'#languiz'}
          >
            <FacebookIcon size={48} round={true} />
          </FacebookShareButton>

          <LinkedinShareButton
            url={"https://www.languiz.com"}
            title={"The Language Quiz"}
            summary={`${shareTitle}\nTest your skills at languiz.com`}
            description={shareTitle}>
              <LinkedinIcon size={48} round={true} />
          </LinkedinShareButton>

          <TwitterShareButton
            url={shareTitle}
            hashtags={['languiz']}
            >
              <TwitterIcon size={48} round={true} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={"https://www.languiz.com"}
            title={shareTitle}
            separator={"\nTest your skills at "}>
              <WhatsappIcon size={48} round={true} />
          </WhatsappShareButton>
        </SocialSection>
        {isMobile && 
        (<TryAgain>
        <h3>Try again in:</h3>
        <Countdown date={`${year}-${monthString}-${dateString}T00:00:00`} />
        </TryAgain>)}
        {pts >= 1 && 
        (
          <CorrectList>
          <h2>Correct answers: </h2> <ul> {listItems}</ul>{" "}
        </CorrectList>
        )}
        {isMobile && <br/>}
        <About>
          <Popup></Popup>
        </About>
      </>
    );
  }
}

export default GameOver;
