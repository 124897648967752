import { Component } from "react";
import styled from "styled-components";
// import GameCompleted from "./GameCompleted";
//const { translate } = require("../translate");

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 2em auto;

  @media screen and (min-width: 1180px) {
    width: 50%;
  }
`;

const Option = styled.button`
  display: block;
  border: 1px solid #616a94;
  border-radius: 15px;
  padding: 15px 30px;
  text-decoration: none;
  color: #616a94;
  background-color: #161a31;
  transition: 0.3s;
  font-size: 1em;
  outline: none;
  user-select: none;
  margin-top: 1em;
  cursor: pointer;

  @media screen and (min-width: 1180px) {
    &:hover {
      color: white;
      background-color: #616a94;
    }
  }
`;

const QuestionTitle = styled.div`
  width: 70%;
  margin: 0 auto;
`;

const QuizWindow = styled.div`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  margin-top: 10vh;
`;

class Question extends Component {
    // componentDidMount() {
    //   setTimeout(this.props.setShow(true), 1000);
    // }
  // componentDidMount(){
  //   let {quiz} = this.props
    
  //   console.log(quiz)
  // }

  resetOptionsColor() {
    var buttons = document.getElementsByTagName("button");
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].style.backgroundColor = "#161a31";
    }
  }

updateLivesInLS(l)
{
    localStorage.setItem('lives', JSON.stringify(l))
}

updatePtsInLS(p)
{
    localStorage.setItem('pts', JSON.stringify(p))
}

updateGuessedCorrectly_InLS(g)
{
    localStorage.setItem('guessedCorrectly', JSON.stringify(g))
}

  render() {
    let {
      showText,
      setShow,
      question,
      answer,
      options,
      pts,
      setPoints,
      lives,
      setLives,
      guessedCorrectly,
      quiz,
      setQuiz,
      indx,
    } = this.props;

    const pickAnswer = (e) => {
      //if (quiz != null) {
        // setTimeout(() => {
        //   // quiz.splice(indx, 1);
        //   // setQuiz(quiz);
        //   this.resetOptionsColor();
        // }, 1000);

      this.resetOptionsColor()
      let userAnswer = e.target.outerText;
      if (userAnswer === answer) {
        setPoints(pts + 1); 
        e.target.style.backgroundColor = "green";
        //this.updatePtsInLS(pts + 1);
        guessedCorrectly.push(userAnswer);
        this.updateGuessedCorrectly_InLS(guessedCorrectly)
        setQuiz(quiz);
      } else {
        setLives(lives - 1);
        e.target.style.backgroundColor = "red";
        this.updateLivesInLS(lives - 1)
      }
  
      // if (quiz.length > 0){
        quiz.splice(indx, 1); 
        setQuiz(quiz)
        localStorage.setItem('quiz', JSON.stringify(quiz))
        indx = Math.floor(Math.random() * quiz.length);
        question = quiz[indx].text

        setInterval(() => {setShow(false)}, 1500)
      // }
      if (quiz.length !== 0)
        localStorage.setItem('currentLanguage',  JSON.stringify(quiz[indx].answer))
    }
    return (
      <>
        <QuestionTitle
          dangerouslySetInnerHTML={{ __html: question }}
        ></QuestionTitle>
        <Options>
          {options.map((language, index) => (
            <Option
              key={index}
              dangerouslySetInnerHTML={{ __html: language }}
              onClick={pickAnswer}
            ></Option>
          ))}
        </Options>

        {/* {quiz.length === 0 && (
          <>
            <QuizWindow>
              <GameCompleted pts={pts} />
            </QuizWindow>
          </>
        )} */}
      </>
    );
  }
}
export default Question;
