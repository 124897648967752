import React from "react";
import styled, { css } from "styled-components/macro";
import Button from "./Button";
import Popup from "./Popup"

const Intro = styled.div`
  margin-top: 8em;
  text-align: center;
`;

const About = styled.div`
position: fixed;
right: 0px; bottom: 0px;
  margin-bottom: 20px;
  margin-right: 10px;
  text-align: right;
`;

const btnCSS = css`
  margin-top: 2em;
`;

class Start extends React.Component {
  // componentDidMount(){
  //   if (localStorage.getItem('start') === 'true'){
  //     this.props.startGame();
  //   }
  // }  

  render() {
    return (
      <>
      <Intro>
        <h1>Guess the language!</h1>
        <Button onClick={this.props.startGame} css={btnCSS}>
          Begin
        </Button>
      </Intro>
      <About>
          <Popup></Popup>
      </About>
      </>
    );
  }
}
export default Start;
